import { isBrowser } from "src/utilities/env"
import { StateStorage, createJSONStorage, persist } from "zustand/middleware"

const prefix = "fam-"

/**
 * An SSR-safe zustand storage implementation.
 */
export class SsrStorage implements StateStorage {
  constructor(private readonly storage: () => Storage) {}

  getItem(key: string) {
    if (isBrowser()) {
      return this.storage().getItem(key)
    }
    return null
  }

  setItem(key: string, json: string) {
    if (isBrowser()) {
      this.storage().setItem(key, json)
    }
  }

  removeItem(key: string) {
    if (isBrowser()) {
      this.storage().removeItem(key)
    }
  }
}

/**
 * A wrapper around the default zustand persist plugin.
 *
 * - SSR-safe (does not throw on accessing localStorage on the server)
 * - Prefixes all keys to avoid collisions
 * - By default, resets state to the defaults on a version change
 *
 * @see https://docs.pmnd.rs/zustand/integrations/persisting-store-data
 */
export const localStoragePersist: typeof persist = (storageConfig, persistConfig) => {
  if (!persistConfig?.name) {
    throw new Error("Please provide a name")
  }

  return persist(storageConfig, {
    ...persistConfig,
    name: `${prefix}${persistConfig.name}`,
    storage: persistConfig.storage || createJSONStorage(() => new SsrStorage(() => localStorage)),

    // We provide a default migration that resets the state to the current
    // defaults. This avoids runtime errors when a user has an old version of
    // the state in their local storage that no longer matches new code.
    migrate: (persistedState, version) => {
      const oldVersion = version || 0
      const newVersion = persistConfig.version || 0

      console.debug(
        `📦 Local storage state "${persistConfig.name}" was reset because it changed from v${oldVersion} to v${newVersion}.`,
      )

      const createDefaultState = storageConfig as () => any
      return createDefaultState()
    },
  })
}
